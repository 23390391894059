<script>
export default {
    name: 'AuthRedirect',
    data() {
        return {
            code: null
        }
    },
    created() {
        this.code = this.getCode()
        if (this.code) {
            this.firstLogin()
        } else {
            this.$router.push('/')
        }
    },
    render: function (h) {
        return h() // avoid warning message
    },
    methods: {
        getCode() {
            const hash = window.location.search.substring(1);
            const vars = hash.split("&")
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=")
                if (pair[0] == 'c') {
                    return pair[1]
                }
            }
            return (false)
        },
        firstLogin() {
            this.$store.dispatch('user/firstLogin', { code: this.code }).then(() => {
                if(this.$route.query.jump_url) {
                    window.location.href = this.$route.query.jump_url
                } else {
                    window.location.href = this.$workBasePath
                }
            }).catch(() => {
                this.$router.push('/')
            })
        }
    }
}
</script>
